.menu {
    width: 28rem;
    gap:10px;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 55%;
    transform: translateY(-60%);
    left: 7vw;
}

.item {
    height: 4.7rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.title {
    color: white;
    font-size: 2rem;
    padding-right: 2.2rem;
}

.item.active .title {
    font-size: 2.8rem;
}

.sub-container {
    color: white;
    width: 30rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 22%;
    right: 8vw;
    cursor: pointer;
}

.active-subheading {
    cursor: default;
}

.item.active:nth-child(1) {
    background: linear-gradient(90deg, var(--transparent), var(--cyan) 70%);
    border-radius: var(--radius-right);
}

.item.active:nth-child(2) {
    background: linear-gradient(90deg, var(--transparent), var(--blue) 70%);
    border-radius: var(--radius-right);
}

.item.active:nth-child(3) {
    background: linear-gradient(90deg, var(--transparent), var(--purple) 70%);
    border-radius: var(--radius-right);
}

.sub-container-1.active-subheading h3,
.sub-container-2.active-subheading h3,
.sub-container-3.active-subheading h3 {
    border-radius: var(--radius-left);
}

.sub-container-1.active-subheading h3 {
    background: linear-gradient(90deg, var(--cyan), var(--transparent) 100%);
}

.sub-container-2.active-subheading h3 {
    background: linear-gradient(90deg, var(--blue), var(--transparent) 100%);
}

.sub-container-3.active-subheading h3 {
    background: linear-gradient(90deg, var(--purple), var(--transparent) 100%);
}

.item.active {
    position: relative;
    cursor: default;
}

.p-container {
    display: none;
}

.active-subheading .p-container {
    display: block;
    font-size: 1.4rem;
}

.icon-title-container {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1rem;
    cursor: default;
}

.subtitle{
    font-size:2.2rem;
}

.icon {
    width: 3rem;
    height: 3rem;
}

.sub-container-1 h3,
.sub-container-2 h3,
.sub-container-3 h3 {
    position: relative;
    margin: 0.2rem 0;
    font-size: 1.8rem;
    padding: 0.5rem 0 0.5rem 1rem;
}

@media(max-width:1620px){
    .sub-container{
        width:24rem;
        right:2rem;
        top:20vh;
    }
    .item.active .title{
        font-size: 2rem;
    }
    
    .title{
        font-size: 1.7rem;
    }
    .menu{
        width:20rem;
        left:8vh;
    }
    .active-subheading .p-container{
        font-size: 1.3rem;
    }
    }
    
    @media(max-width:1100px){
    .sub-container{
        width:21rem;
        right:1vw;
    }
    .subtitle{
        font-size:1.8rem;
    }
    .sub-container-1 h3,
.sub-container-2 h3,
.sub-container-3 h3 {
    font-size: 1.4rem;
}
       .active-subheading .p-container{
        font-size: 1.2rem;
    } 
    .menu{
        left:2vw;
    }
    .title{
        font-size: 1.7rem;;
    }
    .item{
        height:3rem;
    }
    }
    
    @media (max-width:900px){
        .sub-container{
            width: 20rem;
            top: 48%;
            left:50%;
            transform: translateX(-50%);
        }
        .menu{
            top:33%;
        }
        .title{
            font-size: 1rem;;
        }
        .item.active .title{
            font-size: 1.3rem;
        }
        .item{
            height:2rem;
        }
        .active-subheading .p-container{
            display: block;
            width:25rem;
            transform: translateX(-2rem);
            font-size: 1.1rem;
            margin: 0; /* Reset margin */
            padding: 0; /* Reset padding */
        }
        
        .icon-title-container, .icon{
            display: none;
        }
        .sub-container-1 h3,
    .sub-container-2 h3,
    .sub-container-3 h3 {
        font-size:1.3rem;
    }
    }