/* Container for the entire art menu */
.art-menu-container {
    display: flex;
    flex-direction: column; /* Stack buttons and artwork vertically */
    height: 98vh; /* Full viewport height */
    overflow: hidden; /* Prevent unintended overflow */
  }
  
  /* Buttons Section */
  .art-buttons-container {
    display: flex;
    justify-content: center; /* Center buttons horizontally */
    gap: 2rem; /* Space between buttons */
    padding: 20px;
    z-index: 10; /* Ensure buttons are on top */
    position: relative; /* Sticky buttons at the top */
    top: 90px; /* Stick to the top of the viewport */
  }
  
  /* Individual buttons */
  .art-menu-item {
    height: 3.7rem;
    width: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    transition: all 0.2s ease;
  }
  
  .art-menu-title {
    font-size: 1.2rem;
    color: rgb(243, 164, 17);
  }
  
  /* Active button styling */
  .art-menu-item.activeArt {
    border: 2px solid var(--purple);
    box-shadow: 0 0 5px var(--purple);
    background-color: #7b288bee;
  }
  
  /* Artwork Section */
  .artwork-scrollable {
    z-index: 8;
    top:75px;
    position: relative;
    flex-grow: 1; /* Allow the grid to take the remaining space */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 25px; /* Add padding around the grid */
    height: calc(100vh - 140px); /* Subtract the height of the buttons and any padding/margin */
  }
  
  .artwork-scrollable::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
  }
  
  .artwork-scrollable {
    scrollbar-width: none; /* Hide scrollbar in Firefox */
  }

  @media(max-width: 1100px) {
    .artwork-scrollable {
      max-height: calc(100vh - 300px); /* Adjust for smaller screens (50px for bottom navbar) */
    }

    .art-buttons-container {
      top:50px;
    }
  }