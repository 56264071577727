.media-container {
  text-align: center;
  padding: 20px;
  padding-top: 120px; /* Ensure space for the top nav on larger screens */
}

/* Vertically align the media sections */
.media-sections {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

/* Media section button with background images */
.media-section {
  font-family: "Raleway", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 2.8rem;
  width: 70vw;
  height: 18vh;
  min-height: 80px;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.media-section::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);  /* Initial dark overlay */
  transition: background-color 0.3s ease; /* Smooth transition of the overlay */
  border-radius: 10px;
  z-index: 1;
}

.media-section:hover::after {
  background-color: rgba(0, 0, 0, 0);  /* Remove darkness on hover */
}

.media-section span, .media-section h2 {
  position: relative; /* Ensure text stays above the background */
  z-index: 4;         /* Ensure text is above the overlay */
}

/* Subfolder styling */
.subfolder-list {
  align-items: center;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
}

.subfolder {
  background-size: cover; /* Ensure the background image covers the subfolder button */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent repeating */
  font-size: 2.5rem;
  width: 70vw;
  height: 16vh;
  min-height: 80px;
  cursor: pointer;
  padding: 10px;
  border: none;
  color: rgb(255, 255, 255);
  border:none;
  border-radius: 5px;
  transition: background-color 0.3s;

  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.subfolder::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);  /* Initial dark overlay */
  transition: background-color 0.3s ease; /* Smooth transition of the overlay */
  border-radius: 10px;
  z-index: 1;
}

.subfolder h2 {
  position: relative; /* Ensure text stays above the background */
  z-index: 4;         /* Ensure text is above the overlay */
}

.subfolder:hover::after {
  background-color: rgba(0, 0, 0, 0);  /* Remove darkness on hover */

}

/* Image gallery styling */

/* Back Button Styling */
.back-button {
  position: fixed;
  top: 30px;
  right: 300px;
  z-index: 13;
  font-size: 1.5rem;
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: hsl(182, 100%, 59%);
  color: rgb(233, 140, 0);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex; /* Flexbox to align icon and text */
  align-items: center; /* Center align icon and text */
  gap: 8px; /* Add space between icon and text */
  transition: background-color 0.3s ease;

  /* Pulsing glow animation */
  animation: glowing 1.5s infinite;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 5px hsl(182, 100%, 59%);
  }
  50% {
    box-shadow: 0 0 20px hsl(182, 100%, 59%), 0 0 30px hsl(182, 100%, 59%);
  }
  100% {
    box-shadow: 0 0 5px hsl(182, 100%, 59%);
  }
}

.back-button:hover {
  background-color: hsl(56, 100%, 50%);
}

/* Style the back icon */
.back-icon {
  width: 20px; /* Adjust the size of the icon */
  height: auto;
}

/* Fade overlay that darkens the top of the screen */
.fade-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 135px; /* Match the height of the nav */
  pointer-events: none; /* Allow clicks to pass through */
  background: linear-gradient(to bottom, rgba(54, 32, 32, 0.9), rgba(0, 0, 0, 0)); /* Dark to transparent */
  z-index: 8; /* Ensure it's behind the nav but above content */
  opacity: 0; /* Start with no opacity */
  transition: opacity 0.3s ease; /* Smooth transition when opacity changes */
}

/* Fade overlay for the bottom of the screen */
.fade-overlay-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 160px; /* Adjust height as necessary */
  pointer-events: none; /* Allow clicks to pass through */
  background: linear-gradient(to top, rgba(34, 17, 17, 0.9), rgba(0, 0, 0, 0)); /* Dark at the bottom to transparent */
  z-index: 8; /* Ensure it's behind the nav but above content */
  opacity: 0; /* Start with no opacity */
  transition: opacity 0.3s ease; /* Smooth transition when opacity changes */
}


@media(max-width:1600px){
  .back-button {
    right: 200px;
  }
}
/* For mobile views (with nav at the bottom) */
@media (max-width: 1158px) {
  .media-container {
    padding-top: 90px;
  }

  .media-section {
    width: 80vw; /* Adjust button size for smaller screens */
  }

  .subfolder {
    font-size: 1rem;
    padding: 8px;
  }

  .back-button {
    padding: 8px 16px;
    right:30px;
  }

  /* Bottom fade effect is only visible on mobile screens */
  .fade-overlay-bottom {
    opacity: 1; /* Make the bottom fade visible on mobile */
  }
}