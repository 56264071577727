.nav {
    position: fixed;
    display: flex;
    align-items: flex-start;
    top: 1rem;
    gap: 1.5625rem;
    left: 50%;
    z-index: 1000;
}

.nav-about {
    transform: translateX(-1.56rem);
}

.nav-life {
    transform: translateX(-6.25rem);
}

.nav-art {
    transform: translateX(-10.94rem);
}

.nav-projects {
    transform: translateX(-15.62rem);
}

.nav-blog {
    transform: translateX(-20.28rem);
}

.nav-link img {
    width: 2.8rem;
}

a {
    width: 3.125rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    text-decoration: none; /* Removes the underline from anchor elements */
}

.page-title {
    font-family: "Raleway", serif;
    font-optical-sizing: auto;
    font-weight: 650;
    font-style: normal;

    font-size: 0.875rem;
    color: white;
    cursor: default;
}

.nav-link {
    opacity: 0.65;
}

.nav-link:hover {
    opacity: 0.95;
}

.nav-link.current {
    opacity: 1;
}

@media (max-width: 1100px) {
    .nav-about,
    .nav-art,
    .nav-blog,
    .nav-projects,
    .nav-life {
        transform: translateX(0);
    }

    .nav {
        top: calc(100vh - 4rem);
        transform: translateX(-50%);
    }

    .nav-link {
        width: 2rem;
    }

    .nav-link img {
        width: 2rem;
    }

    .page-title {
        font-size: 0.775rem;
    }
}