@import url('https://fonts.googleapis.com/css2?family=Lora:ital@0;1&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


/* General blog page styles */
.blog-page {
  display:flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden; /* Prevent page overflow */
}

/* Page container with fixed size */
.page-container {
  width: 73vw;
  height: 70vh;
  padding: 1.7rem;
  top:28px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Allows content to scroll if it exceeds the height */
  position: relative;
}

/* Content page styling */
.content-header {
  color: #333;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.content-section {
  font-size: 1rem;
  color: #333;
}


/* Content item styling */
.content-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.1rem;
}

.content-item:hover {
  background-color: #f0f0f0;
}

.content-title {
  font-weight: 500;
  color: #333;
}

.content-date {
  color: #888;
  font-size: 1rem;
}

/* Post page styling */
.post-content {
  font-size: 1.13rem;
  color: #333;
  line-height: 1.6;
  white-space: pre-wrap; /* Retain line breaks */
  margin-bottom:2rem;
}

.titular{
  font-size: 1.4rem;
}

.post-date {
  font-size: 1.1rem;
  color: #888;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 1rem;
  width: 100%;
}

.prev-next-button {
  padding: 10px;
  font-size: 1rem;
  width:130px;
  display: flex; /* Flexbox to align icon and text */
  align-items: center; /* Center align icon and text */
  gap: 8px; /* Add space between icon and text */
  background-color: #4d749e;
  justify-content: center;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.prev-next-button:hover {
  background-color: #3b85d3;
}

.prev-next-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.return-button {
  padding: 0.7rem 1.5rem;
  font-size: 1rem;
  background-color: #4a9e5d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.return-button:hover {
  background-color: #18c03c;
}

.prev-icon {
  width: 25px; /* Adjust the size of the icon */
  height: auto;
}

.next-icon{
  width: 25px; /* Adjust the size of the icon */
  height: auto;
}

/* Media query for smaller screens */
@media (max-width: 1100px) {
  .page-container {
    width: 88vw;
    height: 60vh;
    top:-10px;
  }

  .content-header {
    font-size: 1.75rem;
  }


  .content-item {
    font-size: 1rem;
  }

  .post-content {
    font-size: 1.1rem;
  }

  .return-button {
    padding: 0.6rem 1.2rem;
  }

  .prev-next-button, .return-button {
    padding: 0.6rem 1.2rem;
  }
}