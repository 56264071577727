/* Container for the entire grid */
.art-grid-container {
    border-radius: 10px;
    padding: 0; /* Remove extra padding */
    margin: 0 auto; /* Center the grid horizontally */
    max-width: 97%;
    height: auto; /* Ensure it adjusts to content height */
    overflow: visible; /* Let the grid expand */
    
  }
  
  .art-grid-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
  }
  
  /* Grid layout for items */
  .art-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* Responsive grid with rows */
    gap: 20px; /* Space between items */
    margin-bottom: 60px; /* Add margin to ensure the last row is fully visible */
  }
  /* Each art item */
  .art-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
  }
  
  /* Image container */
  .art-image-container {
    width: 100%; /* Full width of the grid column */
    height: 250px; /* Fixed height for consistency */
    position: relative;
    overflow: hidden;
  }
  
  .art-image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure the entire image is visible */
    transition: transform 0.3s ease-in-out;
  }
  
  /* Zoom effect on hover */
  .art-item:hover .art-image-container img {
    transform: scale(1.7); /* Slight zoom on hover */
  }
  
  /* Art title */
  .art-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 10px 0;
    color: #e2e0e0;
  }
  
 /* Default Art Description */
/* Default Art Description */
.art-description {
  font-size: 1rem;
  color: #fad5b7;
  max-height: 50px; /* Truncated height */
  max-width: 100%; /* Prevent horizontal overflow */
  overflow: hidden; /* Ensure content is clipped */
  text-overflow: ellipsis;
  white-space: pre-wrap; /* Preserve spaces and line breaks, but allow wrapping */
  overflow-wrap: break-word; /* Handle long words */
  word-wrap: break-word; /* Fallback for older browsers */
  margin: 0;
  padding: 0;
  transition: max-height 0.3s ease; /* Smooth transition effect */
}

/* Expanded Art Description */
.art-description.expanded {
  max-height: 600px; /* Expanded height */
  overflow: hidden; /* Prevent content from spilling out */
  text-overflow: ellipsis;
  white-space: pre-wrap; /* Preserve spaces and line breaks, but allow wrapping */
  overflow-wrap: break-word; /* Handle long words */
  word-wrap: break-word; /* Fallback for older browsers */
}