/* Masonry-style image gallery using CSS Columns */
.image-gallery {
    column-count: 5; /* Number of columns, adjust for responsiveness */
    column-gap: 25px; /* Horizontal gap between columns */
  }
  
  .gallery-item {
    margin-bottom: 10px; /* Vertical gap between images */
    break-inside: avoid; /* Prevent images from breaking between columns */
  }
  
  .gallery-item img {
    width: 100%; /* Ensure images are responsive */
    height: auto; /* Maintain the original aspect ratio */
    display: block;
    transition: transform 0.3s ease;
  }
  
  .gallery-item:hover img {
    transform: scale(1.01); /* Slight zoom on hover */
  }



  @media (max-width: 1350px){
    .image-gallery {
        column-count: 3; /* Number of columns, adjust for responsiveness */
        column-gap: 20px; /* Horizontal gap between columns */
      }
  }
  /* For mobile views (with nav at the bottom) */
@media (max-width: 700px) {
    .image-gallery {
        column-count: 2; /* Number of columns, adjust for responsiveness */
        column-gap: 20px; /* Horizontal gap between columns */
      }
      
  }

