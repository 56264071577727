#bg{
    object-fit: cover;
    width:100vw;
    height:100vh;
    position:fixed;
    top:0;
    left:0;
    z-index:-5;
}

.shadow-overlay{
    width:100vw;
    height:100vh;
    position: fixed;
    top:0;
    left:0;
    z-index:-4;
    background-color: rgba(0,0,0,0.4);
}

@media (max-width:1200px){
    .shadow-overlay{
        background-color: rgba(0,0,0,0.7);
    }
}