@import url("https://fonts.googleapis.com/css2?family=Russo+One&");
@import url('https://fonts.googleapis.com/css2?family=Anton+SC&family=Neuton&family=Play:wght@400;700&display=swap');

h1{
    font-family: "Russo One", sans-serif;
    -webkit-tap-highlight-color: transparent;
    
}

h2{
    font-family: "Raleway", serif;
    font-optical-sizing: auto;
    font-weight: 767;
    font-style: normal;
  }
  

h3{
    font-family: "Play", sans-serif;
    font-weight: 700;
    -webkit-tap-highlight-color: transparent;
}

body{
    font-family: "Lora", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }


:root{
    --purple:#ffa825ea;
    --blue:#8bc04e;
    --cyan:#25afce;
    --transparent: #3d343400;
    --radius-left: 3rem 0 0 3rem;
    --radius-right: 0 3rem 3rem 0;

}

button:hover,
.class-sub-container a:hover,
.project-item:hover,
.art-item:hover,
.blog-item:hover,
.life-item:hover,
.item:not(.active):hover,
.sub-container-2:not(.active-subheading):hover,
.sub-container-3:not(.active-subheading):hover,
.filter-option:hover{
    background-image:linear-gradient(98deg, #ffffff00, #ffffff42 70%, #ffffff00)
}