@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

/* Container for the project cards */
.project-card-container {
    margin-top: 10rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(315px, 1fr));
    gap: 2rem;
    padding: 2rem;
    justify-items: center;
    overflow-x: hidden; /* Hide horizontal scrollbar */
    overflow-y: hidden; /* Hide vertical scrollbar */
    user-select: none; /* Prevent content selection */
  }
  
  /* Each individual project card */
  .project-card {
    perspective: 1000px;
    width: 100%;
    max-width: 360px;
    min-width: 300px;
    height: 500px;
    position: relative;
    cursor: pointer;
    transition: transform 0.6s ease-in-out;
    user-select: none; /* Prevent content selection */
  }
  
  /* Front and back of the card */
  .project-card-front,
  .project-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
  }
  
  /* Front of the card */
  .project-card-front {
    background: white;
    transform: rotateY(0deg);
  }
  
  .project-card-front img.project-image {
    width: 100%;
    height: 72%;
    object-fit: cover;
  }
  
  .project-title {
    color: #67af08;
    margin: 1rem 0 0 0;
    font-size: 1.8em;
    text-align: center;
  }
  
  .project-description {
    font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 450;
  font-style: normal;
    margin: 0.6rem;
    font-size: 16px;
    color: #201f1f;
    text-overflow:ellipsis;
    overflow: hidden;
    white-space: nowrap;
    opacity: 0.8;
  }
  
  /* Back of the card */
  .project-card-back {
    background: #ffffff;
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribute content across the height */
    align-items: center; /* Center content horizontally */
    padding: 0; /* Remove padding from the card */
  }
  
  .project-card-back .link-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .project-card-back .desc{
    font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
    
    text-align: center; /* Center description text */
    font-size: 16px;
    margin:0 0.6rem 0 0.6rem;
    color: rgb(0, 0, 0);
  }
  
  .project-card-back a {
    font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 450;
  font-style: normal;
    text-decoration: none;
    color: white;
    background-color: #000000;
    
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .project-card-back a:hover {
    background-color: #3584d8;
  }
  
  .project-award {
    font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
    margin: 0 0.6rem 0 0.6rem;
    font-size: 0.9rem;
    color: #359e0b;
    text-align: center;
  }
  
  /* Disable text selection for all content inside the cards */
  .project-card * {
    user-select: none; /* Prevent content selection */
  }
  
  /* Hover-based flip effect for non-mobile screens */
  @media (min-width: 769px) {
    .project-card:hover .project-card-front {
      transform: rotateY(180deg);
    }
  
    .project-card:hover .project-card-back {
      transform: rotateY(0deg);
    }
  }
  
  /* Card flip effect when clicked (for mobile screens) */
  .project-card.flipped .project-card-front {
    transform: rotateY(180deg);
  }
  
  .project-card.flipped .project-card-back {
    transform: rotateY(0deg);
  }
  
  /* Mobile-specific styles */
  @media (max-width: 768px) {
    .project-card-container {
      grid-template-columns: 1fr;
      margin-top: 4rem;
      overflow-y: auto; /* Enable vertical scrolling only on mobile */
      scrollbar-width: none; /* Hide scrollbar on Firefox */
    }
  
    .project-card {
      height: 450px;
    }
  
    .project-card-front img.project-image {
      height: 50%;
    }
  
    .project-description {
      white-space: normal;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;  /* Limit to 3 lines */
      -webkit-box-orient: vertical;
    }
  }
  
  /* Adjust margin-top for screens between 1100px and 768px */
  @media (max-width: 1100px) {
    .project-card-container {
      margin-top: 4rem;
    }
  }
  
  @media (max-width: 480px) {
    .project-card {
      height: 500px;
    }
  
    .project-card-front img.project-image {
      height: 50%;
    }
    
    .project-description {
      font-size: 0.8rem;
    }
  }
  
  /* Hide scrollbars across browsers */
  .project-card-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
  }
  
  .project-card-container {
    -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer and Edge */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
  }