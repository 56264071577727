#playerStats {
    color: white;
    width:20rem;
    height:4rem;
    position:fixed;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    left:3rem;
    top:1.5rem;
    z-index: 1000;
}

h1{
    margin:0;
    font-size: 1.5rem;
}

#playerStats h1 .cursor {
    display: inline-block;
    background-color: black;
    width: 1px;
    margin-left: 2px;
    animation: blink 1s infinite;
}

.identity{
    color: rgb(255, 208, 0);
    font-size: 1.8rem;
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}
@media (max-width:1100px){
    #playerStats{
        left:50%;
        transform: translateX(-50%);
        width: 9rem;
        height: 2.5rem;
        top:1rem
    }

    h1{
        font-size: 1rem;
    }

    .identity{
        font-size: 1.3rem;
    }
}