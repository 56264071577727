.avatar{
    position:fixed;
    overflow: hidden;
    z-index:-2;
}

.avatar.about {
    height:108vh;
    bottom:4vh;
    left: 49%;
    transform: translateX(-50%);
}

.avatar:hover{
    transition-duration: 0.2s;
    transition-timing-function:linear;
    scale:1.002;
}
.avatar.art {
    height:185vh;
    bottom:-110vh;
    left:-1.5rem;
    transform: translateX(0%) scaleX(-1);
    opacity: 0%;
}

.shadow-overlay-art{
    height:100vh;
    width:100vh;
    display: block;
    position: fixed;
    background: linear-gradient(90deg, rgba(0,0,0,0.25), rgba(255,255,255,0));
    z-index:-1;
}

@media (max-width: 1620px){
    .avatar.art{
        z-index: -5;
        height: 90vh;
        bottom: -15vh;
    }
    .avatar.about{
        height:110vh;
    }
}

@media (max-width: 1200px){
    .avatar.about{
        z-index: -5;
        height: 95vh;
        bottom: -13vh;
        top:-5vh;
    }
}
